export default {
  // 首页
  index: {
    'home': 'home',
  },
  // 登录页
  login: {
    'login': 'login',
  }
};
