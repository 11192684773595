import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store';

const openRoutes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '主页',
    },
    component: () => import('../views/home/Home.vue')
  },

  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: () => import('../views/login/Login.vue')
  },

  {
    path: '/:catchAll(.*)',
    component: () => import('../views/404/404.vue')
  },
]

const menuRoutes = [
  {
    path: '/usercenter',
    name: 'UserCenter',
    meta: {
      title: '我的名片',
      icon: 'IdcardTwoTone',
    },
    component: () => import('../views/user/UserCenter.vue')
  },

  {
    path: '/user',
    name: 'UserManage',
    meta: {
      title: '系统管理',
      icon: 'ContactsTwoTone',
    },
    redirect: '/user/list',
    children: [
      {
        path: 'list',
        name: 'UserList',
        meta: {
          title: '🐼 用户列表',
          icon: '',
        },
        component: () => import('../views/user/UserList.vue')
      },
      {
        path: 'role/permission',
        name: 'RolePermission',
        meta: {
          title: '👮 角色权限',
          icon: '',
        },
        component: () => import('../views/user/RolePermission.vue')
      },
      {
        path: 'dept',
        name: 'Dept',
        meta: {
          title: '🥇 组织架构',
          icon: '',
        },
        component: () => import('../views/user/Dept.vue')
      },
    ]
  },

  {
    path: '/blog',
    name: 'BlogManage',
    meta: {
      title: '博客管理',
      icon: 'BookTwoTone',
    },
    redirect: '/blog/write',
    children: [
      {
        path: 'write',
        name: 'WriteBlog',
        meta: {
          title: '📝 写博客',
          icon: '',
        },
        component: () => import('../views/blog/WriteBlog.vue')
      },
      {
        path: 'list',
        name: 'BlogList',
        meta: {
          title: '📔 我的博客',
          icon: '',
        },
        component: () => import('../views/blog/BlogList.vue')
      },

    ]
  },

  {
    path: '/communication',
    name: 'CommunicationManage',
    meta: {
      title: '交际管理',
      icon: 'CrownTwoTone',
    },
    redirect: '/communication/sendEmail',
    children: [
      {
        path: 'sendEmail',
        name: 'SendEmail',
        meta: {
          title: '📧 发邮件',
          icon: '',
        },
        component: () => import('../views/communication/SendEmail.vue')
      },
      {
        path: 'chat',
        name: 'Chat',
        meta: {
          title: '🐧 聊天',
          icon: '',
        },
        component: () => import('../views/communication/Chat.vue')
      },

    ]
  },

  {
    path: '/devops',
    name: 'DevOpsManage',
    meta: {
      title: '运维管理',
      icon: 'CodeTwoTone',
    },
    redirect: '/devops/terminal',
    children: [
      {
        path: 'terminal',
        name: 'XtermTerminal',
        meta: {
          title: '🌐 远程终端',
          icon: '',
        },
        component: () => import('../views/devops/XtermTerminal.vue')
      },
      {
        path: 'host',
        name: 'Host',
        meta: {
          title: '⚔ 服务器',
          icon: '',
        },
        component: () => import('../views/devops/Host.vue')
      },
      {
        path: 'projectDeploy',
        name: 'ProjectDeploy',
        meta: {
          title: '💎 项目部署',
          icon: '',
        },
        component: () => import('../views/devops/ProjectDeploy.vue')
      },

    ]
  },

  {
    path: '/ai',
    name: 'AiManage',
    meta: {
      title: '人工智能管理',
      icon: 'FireTwoTone',
    },
    redirect: '/ai/glm4',
    children: [
      {
        path: 'glm4',
        name: 'Glm4',
        meta: {
          title: '👑 智谱清言',
          icon: '',
        },
        component: () => import('../views/ai/Glm4.vue')
      },
      {
        path: 'codeGeeX',
        name: 'CodeGeeX',
        meta: {
          title: '🤖 代码生成',
          icon: '',
        },
        component: () => import('../views/ai/CodeGeeX.vue')
      },
      {
        path: 'moreModal',
        name: 'MoreModal',
        meta: {
          title: '🎆 多模态',
          icon: '',
        },
        component: () => import('../views/ai/MoreModal.vue')
      },

    ]
  },


]

// 进度条
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes: [...openRoutes, ...menuRoutes],
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // console.log("目标访问路径: " + to.path);
  // console.log("来源访问路径: " + from.path);
  NProgress.start();

  store.dispatch('initState', to.path);

  if (to.path === '/login'/* || to.path === '/'*/) {
    next();
  } else {
    let token = sessionStorage.getItem('token');
    let expire = sessionStorage.getItem('expire');

    if (expire == null || token == null) {
      next("/login");

    } else {
      expire = new Date(expire);
      let now = new Date();
      if (now > expire) {
        next("/login");
      } else {
        next();
      }
    }

  }

});

// 全局后置守卫
router.afterEach(() => {
  NProgress.done()
});

export default router

