export default {
  // 首页
  index: {
    'home': '主页',
  },
  // 登录页
  login: {
    'login': '登录',
  }
};
