import { createStore } from 'vuex'

export default createStore({
  state: {
    // // 选中的菜单
    // selectedKeys: [this.$route.path],
    // // 默认打开的tab页
    // activeKey: this.$route.path,
    // 选中的菜单
    selectedKeys: [],

    // 标签页
    panes: [],
    // 菜单展开或收缩状态
    collapsed: false,
    // 手风琴，展开的菜单
    openKeys: [/*'/user', */'/blog', '/communication', '/devops', '/ai'],
    // 登录名
    loginName: '',
    // 头像
    avatar: '',
    // 主菜单
    mainMenu: [],
    // 角色下拉框
    roleSelectList: [],
    // 默认选中
    roleSelectValue: '',
    // 设置
    showSetting: false,
    // 密码
    password: '',
    // 默认选中
    settingRoleSelectValue: '',

  },
  mutations: {

  },
  actions: {
    initState(state, path) {
      this.state.selectedKeys = [path];
    }
  },
  modules: {

  }
})
