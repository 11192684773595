import { createI18n } from 'vue-i18n';
import enLocale from './en_us.js'
import cnLocale from './zh_cn.js'

const messages = {
    en: {
        ...enLocale
    },
    cn: {
        ...cnLocale
    }
}

const i18n = createI18n({
    // fallbackLocale: 'cn',
    // globalInjection: true,
    // legacy: false,
    locale: sessionStorage.getItem('language') || 'cn',
    messages,
});

export default i18n;
