<template>
  <a-config-provider :locale="zhCN">
    <router-view v-slot="{ Component }">
      <!-- include="UserList,WriteBlog" -->
      <!-- 不写include则都缓存 -->
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>

<!--    <router-view />-->
  </a-config-provider>
</template>

<script>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';

  export default {
    name: 'App',
    data() {
      return {
        zhCN
      }
    }
  }
</script>
