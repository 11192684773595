import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import i18n from '@/i18n';
import {
    Layout,
    Menu,
    Form,
    Input,
    Button,
    Select,
    message,
    Image,
    Tabs,
    Avatar,
    Dropdown,
    Card,
    Tooltip,
    Affix,
    BackTop,
    Row,
    Col,
    Table,
    Pagination,
    Tag,
    Divider,
    ConfigProvider,
    DatePicker,
    Popconfirm,
    Modal,
    Tree,
    Result,
    Space,
    Spin,
    Anchor,
    // Popover,
    Drawer,
    Collapse,
    Carousel,

} from 'ant-design-vue';
// 引入icon
import * as Icons from '@ant-design/icons-vue';

// import MdEditor from 'md-editor-v3';
// import 'md-editor-v3/lib/style.css';

const app = createApp(App);

// eslint-disable-next-line no-undef
axios.defaults.baseURL = '/api';
app.config.globalProperties.$axios = axios;

// icon
app.config.globalProperties.$icons = Icons;
for (const key in Icons) {
    app.component(key, Icons[key]);
}

// app.use(MdEditor);

app.use(i18n);

app.use(router);
app.use(store);

app.use(Layout);  // 布局
app.use(Menu);  // 菜单
app.use(Form);  // 表单
app.use(Row);  // 行
app.use(Col);  // 列
app.use(Input);  // 输入框
app.use(Button);  // 按钮
app.use(Select);  // 下拉框
app.use(message);  // 提示信息
app.use(Image);  // 图片
app.use(Tabs);  // 标签页
app.use(Avatar);  // 头像
app.use(Dropdown);  // 下拉选择
app.use(Card);  // 卡片
app.use(Tooltip);  // 输入框提示
app.use(Affix);  // 固钉（固定菜单）
app.use(BackTop);  // 回到顶部
app.use(Table);  // 表格
app.use(Pagination);  // 分页
app.use(Tag);  // 标签
app.use(Divider);  // 分割线
app.use(ConfigProvider);  // 全局化配置
app.use(DatePicker);  // 日期选择框
app.use(Popconfirm);  // 气泡确认框
app.use(Modal);  // 对话框
app.use(Tree);  // 树形控件
app.use(Result);  // 结果
app.use(Space);  // 间距
app.use(Spin);  // 加载中
app.use(Anchor);  // 锚点
app.use(Drawer);  // 抽屉
app.use(Collapse);  // 折叠框
app.use(Carousel);  // 走马灯

// app.use(Popover); // 气泡卡


app.mount('#app');

